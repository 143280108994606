
import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import Footer from "./footer";
import { useTable, useSortBy, usePagination } from "react-table";
import { csv, filter } from 'd3'
import csvdata from './rankbyviewsandhrs.csv';
import "./table1.css";
import netflix from "../assets/netflix.svg"




// Updated FilterOptions component
// const FilterOptions = ({ options, selectedOptions, onChange, columnHeader }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleCheckboxChange = (option) => {
//         const updatedOptions = selectedOptions.includes(option)
//             ? selectedOptions.filter((selected) => selected !== option)
//             : [...selectedOptions, option];

//         onChange(updatedOptions);
//     };

//     return (
//         <div className="filter-dropdown">
//             <div className="filter-trigger" onClick={toggleDropdown}>
//                 Select
//             </div>
//             {isOpen && (
//                 <div className="filter-options-options">
//                     <div className="options-options-container">
//                         {columnHeader === 'production_company' && (
//                             <div>
//                                 <input
//                                     className="textboxpc"
//                                     type="text"
//                                     placeholder="Search..."
//                                     onInput={(e) => onChange([e.target.value])}
//                                 />
//                                 {options.map((option) => (
//                                     <label key={option}>
//                                         <input
//                                             type="checkbox"
//                                             value={option}
//                                             checked={selectedOptions.includes(option)}
//                                             onChange={() => handleCheckboxChange(option)}
//                                         />
//                                         {option}
//                                     </label>
//                                 ))}
//                             </div>
//                         )}
//                         {columnHeader !== 'production_company' && (
//                             options.map((option) => (
//                                 <label key={option}>
//                                     <input
//                                         type="checkbox"
//                                         value={option}
//                                         checked={selectedOptions.includes(option)}
//                                         onChange={() => handleCheckboxChange(option)}
//                                     />
//                                     {option}
//                                 </label>
//                             ))
//                         )}
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
const FilterOptions = ({ options, selectedOptions, onChange, columnHeader }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (option) => {
        let updatedOptions;


        if (columnHeader === 'type') {
            // console.log("selectedoptions", selectedOptions)
            // console.log("option", option)
            // // For 'anime' and 'reality_docs', toggle the selected state
            updatedOptions = selectedOptions.includes(option)
                ? selectedOptions.filter((selected) => selected !== option)
                : [...selectedOptions, option]
        } 
        else if( (columnHeader === 'netflix_original')&& (option !== "1")) {
            // console.log("selectedoptions", selectedOptions)
            // console.log("option", option)
            // For 'Netflix Original', toggle the selected state only if the value is not equal to 0
            updatedOptions = selectedOptions.includes(option) && option !== "1"
                ? selectedOptions.filter((selected) => selected !== option)
                : [...selectedOptions, option];
        }else {
            // console.log("selectedoptions", selectedOptions)
            // console.log("option", option)
            // For other options, update as usual
            updatedOptions = selectedOptions.includes(option)
                ? selectedOptions.filter((selected) => selected !== option)
                : [...selectedOptions, option];
        }
        // console.log("Updatedoptions", updatedOptions)

        onChange(updatedOptions);
    };

    let content;
    if (columnHeader === 'production_company') {
        content = (
            <>
                <input
                    className="textboxpc"
                    type="text"
                    placeholder="Search..."
                    onInput={(e) => onChange([e.target.value])}
                />
                {options.map((option) => (
                    <label key={option}>
                        <input
                            type="checkbox"
                            value={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                        />
                        {option}
                    </label>
                ))}
            </>
        );
    }
    else if (columnHeader === 'kids') {
        content = options.map((option) => (
            <label key={option}>
                <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                {option === "1" ? "Yes" : "No"}
            </label>
        ));
    }
    else if (columnHeader === 'netflix_original') {
        content = options.map((option) => (
            <label key={option}>
                <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                {option === "1" ? "Yes" : "No"}
            </label>
        ));
    }
    else if (columnHeader === 'type') {
        content = options.map((option) => (
            <label key={option}>
                <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                {option}
            </label>
        ));
    }
    else {
        content = options.map((option) => (
            <label key={option}>
                <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                {option}
            </label>
        ));
    }

    return (
        <div className="filter-dropdown">
            <div className="filter-trigger" onClick={toggleDropdown}>
                Select
            </div>
            {isOpen && (
                <div className="filter-options-options">
                    <div className="options-options-container">
                        {content}
                    </div>
                </div>
            )}
        </div>
    );
};


// Define the columns you want to display in the main table
const mainTableColumns = [
    { Header: "Rank", accessor: "rank" },
    // { Header: "Serial No", accessor: "serial_no", Cell: ({ row }) => row.index + 1 },
    { Header: "Title", accessor: "original_title" },
    { Header: "Season", accessor: "season_number" },
    { Header: "Type", accessor: "tmdb_type" },
    {
        Header: "Netflix Original", accessor: "netflix_original", Cell: ({ value }) => (
            value === "1" ? <img src={netflix} alt="Netflix Original" /> : value
        ),
    },
    { Header: "Genres", accessor: "genres" },
    { Header: "Language", accessor: "original_language" },
    { Header: "Country of Origin", accessor: "country_of_origin" },
    { Header: "Views", accessor: "view_hour_ratio", Cell: ({ value }) => (value / 1000000).toFixed(2) + "M" },
];

// const applyFilters = (data, filters) => {
//     console.log("Applying Filters:", filters);
//     return data.filter((item) => {
//         return Object.entries(filters).every(([key, values]) => {
//             if (values.length === 0) {
//                 return true; // Filter not applied
//             }

//             if (Array.isArray(item[key])) {
//                 // Handle array filters
//                 return values.every(value => item[key].includes(value));
//             } else {
//                 // Use a case-insensitive substring match for string filters
//                 return values.some(value => String(item[key]).toLowerCase().includes(String(value).toLowerCase()));
//             }
//         });
//     });
// };
// const applyFilters = (data, filters) => {
//     console.log("Applying Filters:", filters);
//     return data.filter((item) => {
//         return Object.entries(filters).every(([key, values]) => {
//             if (values.length === 0) {
//                 return true; // Filter not applied
//             }

//             if (Array.isArray(values)) {
//                 // Ensure values is an array before using the some method
//                 return values.some((value) => {
//                     if (Array.isArray(item[key])) {
//                         // Handle array filters
//                         return item[key].includes(value);
//                     } else {
//                         // Use a case-insensitive substring match for string filters
//                         return String(item[key]).toLowerCase().includes(String(value).toLowerCase());
//                     }
//                 });
//             }

//             return false; // Filter not applied if values is not an array
//         });
//     });
// };


const filterColumns = [
    { Header: "Film/Shows", accessor: "tmdb_type" },
    { Header: "Genres", accessor: "genres", },
    { Header: "Language", accessor: "original_language", },
    { Header: "Country of Origin", accessor: "country_of_origin", },
    { Header: "Production Company", accessor: "production_company" },
    { Header: "Netflix Original", accessor: "netflix_original" },
    { Header: "Kids & Family", accessor: "kids" },
    { Header: "Type", accessor: "type" },
    // { Header: "Anime", accessor: "anime" },
    // { Header: "Real", accessor: "reality_docs" },
];

const Views = () => {

    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [optionselect, setOptionselect] = useState();


    const resetFilters = () => {
        setFilters({});
        setOptionselect([]); // Reset additional filter state if needed
        // Add more reset statements if you have other filter-related state
    };
    const applyFilters = (data, filters) => {

        // console.log("Applying Filters:", filters);
        if (filters[""] === "1") {
            delete filters[""];
            delete filters["anime"];
            delete filters["reality_docs"];
        }
    
        return data.filter((item) => {
            return Object.entries(filters).every(([key, value]) => {
                
                if (value === "") {
                    return true; // Filter not applied
                }
                // if (key === 'type' && ['anime', 'kids', 'reality_docs'].includes(value)) {
                //     return item[key] === "1";
                // }
                // if (key === "") {
                //     resetFilters();
                //     return true; // Filter not applied
                // }
                else if ( value.includes('0')&& key === 'netflix_original') {
                    
                    return item[key] !== '1';
                    
                }
    
                else if (Array.isArray(value)) {
                    // Handle array filters
                    return value.every((v) => {
                        if (Array.isArray(item[key])) {
                            // Handle array filters
                            return item[key].includes(v);
                        } else {
                            // Use a case-insensitive substring match for string filters
                            return String(item[key]).toLowerCase().includes(String(v).toLowerCase());
                        }
                    });
                } else {
                    // Check if each character in the input matches the beginning of the options
                    const inputValue = value.toLowerCase();
                    const itemValue = String(item[key]).toLowerCase();
                    return itemValue.includes(inputValue);
                }
            });
        });
    };
    useEffect(() => {
        // Apply filters only if at least one filter is active
        const isAnyFilterActive = Object.values(filters).some(values => values.length > 0);

        if (isAnyFilterActive) {
            setFilteredData(applyFilters(filteredData, filters));
        } else {
            // If no filters are active, reset to the original data
            setFilteredData(csvdata);
        }
    }, [filters]);

    useEffect(() => {
        setFilteredData(applyFilters(filteredData, filters));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    

    // useEffect(() => {
    //     console.log("useEffect triggered");
    //     const fetchData = async () => {
    //         try {
    //             setLoading(true);
    //             const csvData = await csv(csvdata);
    //             console.log("Fetched CSV data:", csvData);

    //             console.log("Filters:", filters);
    //             // console.log("Filtered Data Before:", filteredData);      

    //             // setFilteredData(applyFilters(csvData, filters));
    //             // console.log("Filtered Data After:", filteredData);
    //             const filteredData = csvData.filter(item => item.view_hour_ratio > 0);

    //             // Sort by views
    //             const sortedData = csvData.slice().sort((a, b) => b.view_hour_ratio - a.view_hour_ratio);
    //             console.log("Sorted data:", sortedData);

    //             // // // Add ranks
    //             // const dataWithRanks = sortedData.map((item, index) => ({ ...item, rank: index + 1 }));
    //             // console.log("Data with ranks:", dataWithRanks);
    //             // Assign ranks to each title based on the sorting order
    //             const titleRankMap = new Map();
    //     sortedData.forEach((item, index) => {
    //         titleRankMap.set(item.original_title, index + 1);
    //     });

    //     // Add the rank to each title in the data
    //     const dataWithRanks = csvData.map((item) => ({
    //         ...item,
    //         rank: titleRankMap.get(item.original_title),
    //     }));

    //             // // // Set filtered and sorted data
    //             // setFilteredData(dataWithRanks);
    //             // setLoading(false);

    //             // Sort the data based on "view_hour_ratio"
    //     // const sortedData = csvData.slice().sort((a, b) => b.view_hour_ratio - a.view_hour_ratio);

    //     // // Calculate the ranking based on the sorted order
    //     // const rankedData = sortedData.map((item, index) => ({ ...item, rank: index + 1 }));

    //     // Set both originalData and filteredData with sorted and ranked data
    //     setOriginalData(filteredData);
    //     setFilteredData(filteredData);

    //             console.log("Filtered Data After:", filteredData);
    //         } catch (error) {
    //             console.error("Error fetching or parsing CSV:", error.message);
    //         }
    //         finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [filters], setOriginalData);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const csvData = await csv(csvdata);
                // Calculate the ranking based on the sorted order
                const sortedData = csvData.slice().sort((a, b) => b.view_hour_ratio - a.view_hour_ratio);
                const rankedData = sortedData.map((item, index) => ({ ...item, rank: index + 1 }));
                // console.log("Filters:", filters);
                // console.log("Filtered Data Before:", filteredData);      

                setFilteredData(applyFilters(rankedData, filters));
                setLoading(false);

                // console.log("Filtered Data After:", filteredData);
            } catch (error) {
                console.error("Error fetching or parsing CSV:", error.message);
            }
        };

        fetchData();
    }, [filters],setOriginalData);
    // useEffect(() => {
    //     const applyFiltersAndPreserveRank = () => {
    //         const filtered = applyFilters(originalData, filters);
    
    //         // Preserve the original ranking order
    //         const rankedData = originalData
    //             .filter((item) => filtered.includes(item))
    //             .map((item, index) => ({ ...item, rank: index + 1 }));
    
    //         setFilteredData(rankedData);
    //     };
    
    //     applyFiltersAndPreserveRank();
    // }, [filters, originalData]);
    
    

   

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, sortBy },
        pageCount,
        gotoPage,
        // pageSize: currentPageSize,
        setPageSize: setTablePageSize,

    } = useTable(
        {
            columns: mainTableColumns, // Use the mainTableColumns array for the main table
            data: filteredData,
            initialState: {
                pageIndex: 0, pageSize,
                //  sortBy: [
                //     {
                //         id: "view_hour_ratio",
                //         desc: true,
                //     },
                // ],
            },
            defaultCanSort: true,
            getSortByToggleProps: ({ column }) => ({
                onClick: () => handleSort(column),
            }),
        },
        useSortBy,
        usePagination
    );



    // const handleFilterChange = (column, value) => {
    //     console.log('Before Update Filters:', filters);
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [column]: value,
    //     }));
    //     console.log('After Update Filters:', filters);
    // };
    // const handleFilterChange = (column, value) => {
    //     console.log('Before Update Filters:', filters);

    //     // Check if the input is empty, reset the filter
    //     if (value === "") {
    //         setFilters((prevFilters) => ({
    //             ...prevFilters,
    //             [column]: "",
    //         }));
    //     } else {
    //         // Convert the input value to lowercase for case-insensitive comparison
    //         const inputValue = value.toLowerCase();

    //         setFilters((prevFilters) => ({
    //             ...prevFilters,
    //             [column]: inputValue,
    //         }));
    //     }

    //     console.log('After Update Filters:', filters);
    // };

    function processColumnValues(columnAccessor, values) {
        // Specify columns that require splitting and trimming
        const columnsToProcess = ["tmdb_type", "genres", "country_of_origin", "original_language", "production_company", "netflix_original", "kids", "reality_docs", "anime"];

        if (columnsToProcess.includes(columnAccessor)) {
            // Filter out empty values and then split and trim
            const nonEmptyValues = values.filter(value => value.trim() !== '');
            return nonEmptyValues.flatMap((value) => value.split(',').map((v) => v.trim()));
        }

        return values;
    }



    // const handleDropdownChange = (column, selectedValues) => {
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [column]: selectedValues,
    //     }));
    // };
    const handleDropdownChange = (column, selectedValues) => {
        if (column === 'type') {
            
           
            setOptionselect(selectedValues);

            if (selectedValues === '') {
                
                setFilters((prevFilters) => {
                    const { [selectedValues]: filterToRemove, ...restFilters } = prevFilters;
                    return restFilters;
                });
            } else {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    [selectedValues]: "1",
                }));
            }
            
            // console.log(selectedValues)
            
        } 
        else if(column === 'netflix_original'){
            setOptionselect(selectedValues);
            setFilters((prevFilters) => ({
                ...prevFilters,
                [column]: selectedValues,
            }));}
            
    
        else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [column]: selectedValues,
            }));
        }
    };
    // const handleDropdownChange = (column, selectedValues) => {
    //     // Create a map of columns that require special filtering logic
    //     const specialColumns = {
    //         'type': (item) => ['anime', 'kids', 'reality_docs'].includes(item.type),
    //     };

    //     // Apply special filtering logic if the column is in the specialColumns map
    //     const filterFunction = specialColumns[column];
    //     const filteredDataByColumn = filterFunction ? originalData.filter(filterFunction) : originalData;

    //     // Set filters and filtered data based on the column
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [column]: selectedValues,
    //     }));
    //     setFilteredData(filteredDataByColumn);
    // };


    const handleSort = (column) => {
        const isAsc = column.isSorted ? !column.isSortedDesc : true;
        column.toggleSortBy(isAsc, false);
    };

    // const changePageSize = (size) => {
    //     setPageSize(size);
    // };

    useEffect(() => {
        setTablePageSize(pageSize);
        gotoPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, setTablePageSize, gotoPage]);

    return (
        <>
            <NavBar />
            <div className="vhr-head"><div className="vhr-h1"> H1 2023: Netflix Ratings by Views</div> Views = View Hours / Runtime </div>
            <div className="contable">
                <div className="filter-table">
                    <h2 className="text-center filter-text">Filters</h2>
                    <div className="filter-options">
                        {filterColumns.map((column) => {
                            let options = [];
                            let allValues = filteredData.flatMap((item) => item[column.accessor]);

                            allValues = processColumnValues(column.accessor, allValues);

                            options = [...new Set(allValues)];

                            return (
                                <div key={column.accessor} className="options-container">
                                    <label className="op-label">{column.Header}</label>
                                    {/* {options.length > 0 ? (
                                        <FilterOptions
                                            options={options}
                                            
                                            selectedOptions={filters[column.accessor] || []}
                                            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                            columnHeader = {column.accessor}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value={filters[column.accessor] || ""}
                                            onChange={(e) => handleFilterChange(column.accessor, e.target.value)}
                                        />
                                    )} */}
                                    {/* <FilterOptions
                                            options={options}
                                            
                                            selectedOptions={filters[column.accessor] || []}
                                            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                            columnHeader = {column.accessor}
                                        /> =============================================*/}
                                    {/* {column.accessor === 'type' ? (
                                        <FilterOptions
                                            options={['anime', 'reality_docs']} // Add options for "type" filter
                                            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                            selectedOptions={optionselect || []}
                                            columnHeader={column.accessor}
                                        />
                                    ) : (
                                        <FilterOptions
                                            options={options}
                                            selectedOptions={filters[column.accessor] || []}
                                            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                            columnHeader={column.accessor}
                                        />
                                    )}===============================recent=================== */}
                                    {column.accessor === 'type' 
    ? (
        <FilterOptions
            options={['anime', 'reality_docs']} // Add options for "type" filter
            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
            selectedOptions={optionselect || []}
            columnHeader={column.accessor}
        />
    ) 
    : column.accessor === 'netflix_original' 
        ? (
            <FilterOptions
            options={['1', '0']} // Add options for "type" filter
            onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
            selectedOptions={optionselect || []}
            columnHeader={column.accessor}
        />
            
        ) 
        : (
            <FilterOptions
                options={options}
                selectedOptions={filters[column.accessor] || []}
                onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                columnHeader={column.accessor}
            />
        )
}



                                    {/* {column.accessor === 'production_company' ? (
                                <input
                                className="textboxpc"
                                    type="text"
                                    value={filters[column.accessor] || ""}
                                    onChange={(e) => handleFilterChange(column.accessor, e.target.value)}
                                />
                            ) : (
                                    <FilterOptions
                                        options={options}
                                        selectedOptions={filters[column.accessor] || []}
                                        onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                    />
                            )} */}
                                    {/* <FilterOptions
                                        options={options}
                                        selectedOptions={filters[column.accessor] || []}
                                        onChange={(selectedValues) => handleDropdownChange(column.accessor, selectedValues)}
                                    />   */}

                                </div>
                            );
                        })}




                    </div>
                </div>

                {/* <div className="maintable">
                    <table {...getTableProps()}>
                        <thead className="mth">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render("Cell", { index })}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={() => gotoPage(pageIndex - 1)} disabled={pageIndex === 0}>
                            Prev
                        </button>
                        <span>
                            Page {pageIndex + 1} of {pageCount}
                        </span>
                        <button onClick={() => gotoPage(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
                            Next
                        </button>
                    </div>
                </div> */}
                <div className="maintable">
                    <table {...getTableProps()}>
                        <thead className="mth">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                            </span>
                                        </th>
                                    ))}
                                    {/* Add a new column for serial number */}
                                    {/* <th>serial_no</th> */}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell", { index })}
                                                {/* Display incrementing numbers in the "serial_no" column */}
                                                {/* {cellIndex === 0 && <span> {(pageIndex) * pageSize + index + 1}</span>} */}
                                                {/* {cellIndex === 0 && <span>{row.values.rank}</span>} */}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={() => gotoPage(pageIndex - 1)} disabled={pageIndex === 0}>
                            Prev
                        </button>
                        <span>
                            Page {pageIndex + 1} of {pageCount}
                        </span>
                        <button onClick={() => gotoPage(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
                            Next
                        </button>
                    </div>
                </div>


            </div>
            <Footer />
        </>
    );
};

export default Views;





