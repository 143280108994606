import React from "react"
import {  Link } from "react-router-dom";
import NavBar from "./Navbar"
import './NavBar.css'
import Footer from "./footer";


export default function home() {

  return (
    <>
    <div className="back">
    <NavBar />
    <div className=" text-center  h">Netflix Content Ratings</div>
    <div className="ha text-center ">Signup free to filter data by genre, content type, original language, country of origin, and production company.</div>
    <div className="text-center inm"><Link to="/signup" style={{ color: 'black' }}>Signup</Link></div>
    <div className="d text-center">H12023 : Content with 10M+ View Hours</div>
    <div className="e text-center">Covers 80% of all view hours from the H1Netflixreport</div>
    </div>
    <Footer/>
    </>
  )
}
