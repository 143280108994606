import React, { useRef, useState } from "react";
import {  Link, useNavigate } from "react-router-dom";
import { Alert} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import './signup.css';

// import emailIcon from '../assets/email.svg';
// import lockIcon from '../assets/padlock 1.svg';
import tv from '../assets/tv.png';

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const companyNameRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      // Additional user information
      const additionalUserInfo = {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        companyName: companyNameRef.current.value,
      };

      await signup(emailRef.current.value, passwordRef.current.value, additionalUserInfo);
      history("/");
    } catch (error) {
      console.error('Error during signup:', error);
      setError("Failed to create an account. Please try again later.");
    }finally {
      // Ensure that setLoading(false) is called in a finally block
      setLoading(false);
    }

    
  }
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <>
      <div className="page-container">
        <div className="half-container left">
          <div className="cardl">
            <h2 className="mb-4">Sign Up</h2>
            <div className="w-100 mt-2">
              If you already have an account, register
              <br /> You can <Link to="/login">Login here!</Link>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span>
                      <img src={emailIcon} alt="Email Icon" className="icone" />
                    </span> */}
                  </div>
                  <input
                    type="email"
                    className="inputField form-control custom-placeholder-color"
                    ref={emailRef}
                    required
                    placeholder="Enter your email address"
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="inputField form-control custom-placeholder-color fn"
                  ref={firstNameRef}
                  required
                  placeholder="First Name"
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="inputField form-control custom-placeholder-color ln"
                  ref={lastNameRef}
                  required
                  placeholder="Last Name"
                />
              </div>
              
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="inputField form-control custom-placeholder-color"
                  ref={companyNameRef}
                  required
                  placeholder="Company Name"
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span>
                      <img src={lockIcon} alt="Email Icon" className="icone" />
                    </span> */}
                  </div>
                  <input
                    type="password"
                    className="form-control inputField custom-placeholder-color"
                    ref={passwordRef}
                    required
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Password Confirmation</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span>
                      <img src={lockIcon} alt="Email Icon" className="icone" />
                    </span> */}
                  </div>
                  <input
                    type="password"
                    className="form-control inputField custom-placeholder-color"
                    ref={passwordConfirmRef}
                    required
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              
              <button disabled={loading} className="reg" type="submit">
                Register
              </button>
            </form>
          </div>
        </div>
        {!isSmallScreen && (
          <div className="half-container">
            <div className="right">
              <img src={tv} alt="TV Icon" className="tv" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
