import React, { useContext, useState, useEffect } from "react"
import { auth , firestore} from "../firebase"
import { setDoc, doc } from "firebase/firestore";
import { createUserWithEmailAndPassword , sendPasswordResetEmail,signInWithEmailAndPassword } from "firebase/auth";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  // function signup(email, password, additionalUserInfo) {
  //   const { user } = auth.createUserWithEmailAndPassword(email, password);

  //   // Store additional information in Firestore
  //   firestore.collection("users").doc(user.uid).set({
  //     email: email,
  //     firstName: additionalUserInfo.firstName,
  //     lastName: additionalUserInfo.lastName,
  //     companyName: additionalUserInfo.companyName,
  //   });

  //   console.log("User account created and data stored successfully!");
  //   return user;
  //   // return auth.createUserWithEmailAndPassword(email, password)
  // }
  async function signup(email, password, additionalUserInfo) {
    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional information in Firestore
      await setDoc(doc(firestore, "users", user.uid), {
        email: email,
        firstName: additionalUserInfo.firstName,
        lastName: additionalUserInfo.lastName,
        companyName: additionalUserInfo.companyName,
      });
  
      console.log("User account created and data stored successfully!");
      return user;
    } catch (error) {
      console.error("Error during sign-up and data storage:", error);
      throw error;
    }
  }
  

  function login(email, password) {
    return signInWithEmailAndPassword(auth,email, password)
  }
  // function loginWithGoogle(auth,provider) {
  //   return auth.signInWithPopup(auth,provider);
  // }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth,email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    // loginWithGoogle,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
