// import React from "react"
// import Signup from "./Signup"
// import { Container } from "react-bootstrap"
// import { AuthProvider } from "../contexts/AuthContext"
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

// import Login from "./Login"
// import PrivateRoute from "./PrivateRoute"
// import ForgotPassword from "./ForgotPassword"
// import UpdateProfile from "./UpdateProfile"
// import Home from "./Home"

// import Landing from "./landingpage"
// import ViewHours from "./viewhours"
// import Views from "./views"
// import TopShows from "./topshows"
// import PrivateRouteLogin from "./PrivateRouteLogin"

// function App() {
//   return (

//       <>
//         <Router>
//           <AuthProvider>
//             <Routes>
//             <PrivateRouteLogin path="/topshows" component={TopShows} />
//             {/* <Route path="/home" component={home} /> */}
//             <Route path="/home" element={<Home />} />
//             {/* <Route path="/landing" component={Landing} /> */}
//             <PrivateRouteLogin path="/ViewHours" component={ViewHours} />
//             <PrivateRouteLogin path="/View" component={Views} />
//             <PrivateRoute exact path="/" component={Landing} />
//             {/* <Route path="/filter" component={filter} /> */}
//             {/* <Route path="/signup" component={Signup} />
//               <Route path="/login" component={Login} /> */}
//               <Route path="/signup" element={<Signup />} />
//               <Route path="/login" element={<Login />} />
//             <Container>
//             <PrivateRoute path="/update-profile" component={UpdateProfile} />
//               {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
//               <Route path="/forgot-password" element={<ForgotPassword />} />
//               </Container>
//             </Routes>
//           </AuthProvider>
//         </Router>
//       </>

//   )
// }

// export default App
import React from "react";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PrivateRouteLogin from "./PrivateRouteLogin";
import Signup from "./Signup";
import Login from "./Login";
import UpdateProfile from "./UpdateProfile";
import Home from "./Home";
import Landing from "./landingpage";
import ViewHours from "./viewhours";
import Views from "./views";
import TopShows from "./topshows";
import ForgotPassword from "./ForgotPassword";
import ErrorBoundary from "./error";
import Logout from "./logout";

function App() {
  return (
    <>
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <Routes>
              {/* <PrivateRouteLogin path="/topshows" element={<TopShows />} /> */}
              <Route
                path="/topshows"
                element={
                  <PrivateRouteLogin>
                    <TopShows />
                  </PrivateRouteLogin>
                }
              />
              <Route
                path="/ViewHours"
                element={
                  <PrivateRouteLogin>
                    <ViewHours />
                  </PrivateRouteLogin>
                }
              />
              <Route
                path="/View"
                element={
                  <PrivateRouteLogin>
                    <Views />
                  </PrivateRouteLogin>
                }
              />
              <Route path="/home" element={<Logout />} />

              {/* <PrivateRouteLogin path="/ViewHours" element={<ViewHours />} />
            <PrivateRouteLogin path="/View" element={<Views />} /> */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Landing />
                  </PrivateRoute>
                }
              />
              {/* <PrivateRoute path="/" element={<Landing />} /> */}
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              {/* <Container> */}
              {/* <PrivateRoute path="/update-profile" element={<UpdateProfile />} /> */}
              <Route
                path="/update-profile"
                element={
                  <PrivateRouteLogin>
                    <UpdateProfile />
                  </PrivateRouteLogin>
                }
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* </Container> */}
            </Routes>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
