
import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import Footer from "./footer";
import { useTable, useSortBy, usePagination } from "react-table";
import { csv } from 'd3'
import {  Link } from "react-router-dom";
import csvdata from './landing.csv';
import netflix from "../assets/netflix.svg"
import "./table1.css";
import "./table.css";



const mainTableColumns = [
    { Header: "Serial No", accessor: "serial_no", Cell: ({ row }) => row.index + 1 },
    { Header: "Title", accessor: "Title" },
    { Header: "Seasons", accessor: "Season" },
    { Header: "Type", accessor: "Type" },
    {
        Header: "Netflix Original",
        accessor: "Original", Cell: ({ value }) => (
            value === "1" ? <img src={netflix} alt="Netflix Original" /> : value
        ),
        
    },
    { Header: "Language", accessor: "Language" },
    { Header: "Country of Origin", accessor: "Country" },
    { Header: "View Hours", accessor: "View Hours" },
    { Header: "Views", accessor: "Views"},
];

const applyFilters = (data, filters) => {
    // console.log("Applying Filters:", filters);
    return data.filter((item) => {
        return Object.entries(filters).every(([key, values]) => {
            if (values.length === 0) {
                return true; // Filter not applied
            }

            if (Array.isArray(item[key])) {
                // Handle array filters
                return values.every(value => item[key].includes(value));
            } else {
                // Use a case-insensitive substring match for string filters
                return values.some(value => String(item[key]).toLowerCase().includes(String(value).toLowerCase()));
            }
        });
    });
};


const Logout = () => {
    // const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [loading, setLoading] = useState(true);
    // const [imageLoaded, setImageLoaded] = useState(false);

    // useEffect(() => {
    //     const image = new Image();
    //     image.src = netflix;

    //     image.onload = () => {
    //         setImageLoaded(true);
    //     };

    //     image.onerror = () => {
    //         console.error("Error loading Netflix image");
    //     };

    //     // Clean up the image object when the component unmounts
    //     return () => {
    //         image.onload = null;
    //         image.onerror = null;
    //     };
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const csvData = await csv(csvdata);

                setFilteredData(applyFilters(csvData, filters));
                setLoading(false);

                
            } catch (error) {
                
            }
        };

        fetchData();
    }, [filters]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const csvData = await csv(csvdata);
    //             setFilteredData(applyFilters(csvData, filters));
    //             setLoading(false);
    //         } catch (error) {
    //             console.error("Error fetching or parsing CSV:", error.message);
    //         }
    //     };
    
    //     fetchData();
    // }, [filters]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const csvData = await csv(csvdata);
    
    //             // Update netflix_original values here if needed
    //             const updatedData = csvData.map(item => {
    //                 return {
    //                     ...item,
    //                     netflix_original: item.netflix_original === "1" ? (
    //                         <img src={netflix} alt="Netflix Original" style={{ display: 'block', height: '50px', width: "50px" }} />
    //                     ) : item.netflix_original
    //                 };
    //             });
    
    //             setData(updatedData); // Use setData to update state
    //             setLoading(false);
    //         } catch (error) {
    //             console.error("Error fetching or parsing CSV:", error.message);
    //         }
    //     };
    
    //     fetchData();
    // }, []); // Empty dependency array, run once when component mounts
 

    
    useEffect(() => {
        setFilteredData(applyFilters(filteredData, filters));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex },
        pageCount,
        gotoPage,
        pageSize: currentPageSize,
        setPageSize: setTablePageSize,
    } = useTable(
        {
            columns: mainTableColumns, // Use the mainTableColumns array for the main table
            data: filteredData,
            initialState: { pageIndex: 0, pageSize },
            getSortByToggleProps: ({ column }) => ({
                onClick: () => handleSort(column),
            }),
        },
        useSortBy,
        usePagination
    );

    

    const handleSort = (column) => {
        const isAsc = column.isSorted ? !column.isSortedDesc : true;
        column.toggleSortBy(isAsc, false);
    };

    const changePageSize = (size) => {
        setPageSize(size);
    };
   

    useEffect(() => {
        setTablePageSize(pageSize);
        gotoPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, setTablePageSize, gotoPage]);

    return (
        <>
            <NavBar />
            {/* <div className="contable"> */}
                <>
                <div className="back logout-back">
    <div className=" text-center  h">Netflix Content Ratings</div>
    <div className="ha text-center ">Signup free to filter data by genre, content type, original language, country of origin, and production company.</div>
    <div className="text-center inm"><Link to="/signup" style={{ color: 'black' }}>Signup</Link></div>
    <div className="d text-center">H12023 : Content with 10M+ View Hours</div>
    <div className="e text-center">Covers 80% of all view hours from the H1Netflixreport</div>
    
                {/* <div className="landheading"> H1 2023: Content with 10M+ View Hours</div> */}
                <div className="maintable maintable-landing logout-table-back">
                    <table {...getTableProps()}>
                        <thead className="mth">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render("Cell", { index })}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={() => gotoPage(pageIndex - 1)} disabled={pageIndex === 0}>
                            Prev
                        </button>
                        <span>
                            Page {pageIndex + 1} of {pageCount}
                        </span>
                        <button onClick={() => gotoPage(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
                            Next
                        </button>
                    </div>
                </div>
                </div></>
           
                
            {/* </div> */}
            <Footer />
        </>
    );
};

export default Logout;