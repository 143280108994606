// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fb{
   width: 100%;
   height: 13vh;
   background-color: white;
   display: flex;
   justify-content: center;

}
.foot{
    display: flex;
    margin-top: 1vw;

}
.logocontent{
    font-size: 3vw;
    font-weight: bold;
}
.de{
    margin-left: 2vw;
    font-weight: bold;

}
.contact{
    font-size: 1.5vw;

}
.policy{
    font-size: 1.5vw;
}


@media (max-width: 768px) {
    .fb{
        /* height: 20vh; */
        flex-direction: row;
        bottom: 0;
        /* border: solid; */

    }
    .foot{
        margin-top: 6vw;
        
    
    }
    .logocontent{
        font-size: 6vw;
        margin-left: 5vw;
        margin-top: 4vw;
    }
    .de{
        margin-left: 4vw;
    
    }
    .contact{
        font-size: 3vw;
    }
    .policy{
        margin-top: 2vw;
        font-size: 3vw;
    }

}
`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;GACG,WAAW;GACX,YAAY;GACZ,uBAAuB;GACvB,aAAa;GACb,uBAAuB;;AAE1B;AACA;IACI,aAAa;IACb,eAAe;;AAEnB;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,iBAAiB;;AAErB;AACA;IACI,gBAAgB;;AAEpB;AACA;IACI,gBAAgB;AACpB;;;AAGA;IACI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,SAAS;QACT,mBAAmB;;IAEvB;IACA;QACI,eAAe;;;IAGnB;IACA;QACI,cAAc;QACd,gBAAgB;QAChB,eAAe;IACnB;IACA;QACI,gBAAgB;;IAEpB;IACA;QACI,cAAc;IAClB;IACA;QACI,eAAe;QACf,cAAc;IAClB;;AAEJ","sourcesContent":[".fb{\r\n   width: 100%;\r\n   height: 13vh;\r\n   background-color: white;\r\n   display: flex;\r\n   justify-content: center;\r\n\r\n}\r\n.foot{\r\n    display: flex;\r\n    margin-top: 1vw;\r\n\r\n}\r\n.logocontent{\r\n    font-size: 3vw;\r\n    font-weight: bold;\r\n}\r\n.de{\r\n    margin-left: 2vw;\r\n    font-weight: bold;\r\n\r\n}\r\n.contact{\r\n    font-size: 1.5vw;\r\n\r\n}\r\n.policy{\r\n    font-size: 1.5vw;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n    .fb{\r\n        /* height: 20vh; */\r\n        flex-direction: row;\r\n        bottom: 0;\r\n        /* border: solid; */\r\n\r\n    }\r\n    .foot{\r\n        margin-top: 6vw;\r\n        \r\n    \r\n    }\r\n    .logocontent{\r\n        font-size: 6vw;\r\n        margin-left: 5vw;\r\n        margin-top: 4vw;\r\n    }\r\n    .de{\r\n        margin-left: 4vw;\r\n    \r\n    }\r\n    .contact{\r\n        font-size: 3vw;\r\n    }\r\n    .policy{\r\n        margin-top: 2vw;\r\n        font-size: 3vw;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
