// import React from "react"
// import { Route, Navigate } from "react-router-dom"
// import { useAuth } from "../contexts/AuthContext"

// export default function PrivateRoute({ component: Component, ...rest }) {
//   const { currentUser } = useAuth()

//   return (
//     <Route
//       {...rest}
//       render={props => {
//         return currentUser ? <Component {...props} /> : <Navigate to="/home" />
//       }}
//     ></Route>
//   )
// }
import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// export default function PrivateRoute({ element: Element, ...rest }) {
//   const { currentUser } = useAuth();

//   return (
//     <Route
//       {...rest}
//       element={currentUser ? <Element /> : <Navigate to="/home" />}
//     />
//   );
// }
export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Navigate to="/home" replace />;
  }
  return children;
}
