// import React from "react"
// import ReactDOM from "react-dom"
// import App from "./components/App"
// import "bootstrap/dist/css/bootstrap.min.css"

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// )
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";

function AppWithCallbackAfterRender() {
  

  return <App />;
}


const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppWithCallbackAfterRender />
  </React.StrictMode>
);


