import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import './NavBar.css'

const NavBar = () => {



  const { currentUser, logout } = useAuth();
  return (
    <>
      <nav className="navbar">
        <div className="logo"><Link to="/" style={{ color: 'white', textDecoration: 'none' }} >content&#x2022;demand</Link>

        </div>
        <div className="nav-links">
          <Link to="/ViewHours" style={{ color: 'white' }} >Rank by View Hours</Link>
          <Link to="/View" style={{ color: 'white' }} >Rank by Views</Link>
          <Link to="/topshows" style={{ color: 'white' }} >Top Shows</Link>
          {/* <Link to={{ pathname: "https://contentdemand.substack.com/" }} style={{ color: 'white' }} target="_blank">Blogs</Link> */}
          <a href="https://contentdemand.substack.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
            Blogs
          </a>


        </div>
        <div className="auth-buttons">
          {/* <div className="transparent-button l"><Link to="/login" style={{ color: 'white' }} >Login</Link></div>
        <div className="transparent-button signup snav"><Link to="/signup" style={{ color: 'black' }}>Signup</Link></div> */}
          {currentUser ? (
            // Render a welcome message or user-specific content
            <>
              <div className="welcome-msg " style={{ color: 'white' }}>
                Welcome, {currentUser.email || 'User'}
              </div>
              <div className="transparent-button " style={{ color: 'white' }} onClick={logout}>
                <Link to="/logout" style={{ color: 'white' }} >Logout</Link>

              </div>
            </>
          ) : (
            // Render login and signup buttons if not logged in
            <>
              <div className="transparent-button l"><Link to="/login" style={{ color: 'white' }}>Login</Link></div>
              <div className="transparent-button signup snav"><Link to="/signup" style={{ color: 'black' }}>Signup</Link></div>
            </>
          )}
        </div>
      </nav></>
  )
}

export default NavBar;