import React, { Component } from 'react'

import './footer.css'

export default class Footer extends Component {

  render() {
    return (
    <>
    <footer className='fb'>
      <div className='foot'>
      <div className='logocontent'>content&#x2022;demand</div>
      <div className='de'>
      <div className='contact'>Contact Us: metrics@contentdemand.org</div>
      <div className='policy'>Privacy Policy</div>
      </div>
      </div>
    
    </footer>
    </>
  )
}}

// export default Navbar;