import React, { useEffect, useState } from "react";
import { auth, provider } from "../firebase";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import google from '../assets/google.svg'
import {signInWithRedirect} from "firebase/auth";
export default function GoogleLoginButton() {
  const [value, setValue] = useState("");
  const history = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, you can handle the login result here
        setValue(user.email);
        localStorage.setItem("email", user.email);

        // Redirect to "/" after successful login
        history("/");
      } else {
        // User is signed out or the redirect was canceled
        setValue("");
        localStorage.removeItem("email");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [history]); // Add history as a dependency

  async function handleGoogleLogin() {
    try {
      // Initiate the Google sign-in redirect
      await signInWithRedirect(auth,provider);
    } catch (error) {
      // Handle errors or redirect cancellations here
      console.error("Google login failed or redirect canceled", error);
    }
  }

  return (
    <><div className="google-text w-100 text-center mt-3"> or continue with</div>
      <button onClick={handleGoogleLogin} className="google-login-button">
        <img src={google}/>
      </button>
    </>
  );
}
