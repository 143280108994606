// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .left{
    margin-top: 5vw;
  }

  .google-login-button{
    background-color: transparent;
    border: none;
    margin-left: 16.5vw;
    margin-top: 2vw;
  }
  .google-text{
    /* margin-left: 14vw;
    margin-top: 3.5vw; */
    color: rgb(163, 156, 156);
    font-weight: 10vw;
    /* font-size: 1.2vw; */
  }

  .checkbox{
    position:absolute;
    width: 20vw;
    /* margin-top: 1vw;
    font-size: 1.1vw; */
  }
  input[type=checkbox]{
    margin-right: 0.4vw;
    height: 1vw;
  }

  @media (max-width: 768px) {
    .google-login-button{
      margin-left: 14vh;
      margin-top: 2vw;
    }
    .log{
      margin-left: 26vw;
    }
    input[type=checkbox]{
      margin-right: 0.4vw;
      height: 10px;
      width: 10px;
    }
    .checkbox{
      width: 35vw;
    }
    .log-left{
      width: 70vw;
    }
    .login-log{
      margin-left: 9vh;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/login.css"],"names":[],"mappings":";EACE;IACE,eAAe;EACjB;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE;wBACoB;IACpB,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX;uBACmB;EACrB;EACA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE;MACE,iBAAiB;MACjB,eAAe;IACjB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,mBAAmB;MACnB,YAAY;MACZ,WAAW;IACb;IACA;MACE,WAAW;IACb;IACA;MACE,WAAW;IACb;IACA;MACE,gBAAgB;IAClB;EACF","sourcesContent":["\r\n  .left{\r\n    margin-top: 5vw;\r\n  }\r\n\r\n  .google-login-button{\r\n    background-color: transparent;\r\n    border: none;\r\n    margin-left: 16.5vw;\r\n    margin-top: 2vw;\r\n  }\r\n  .google-text{\r\n    /* margin-left: 14vw;\r\n    margin-top: 3.5vw; */\r\n    color: rgb(163, 156, 156);\r\n    font-weight: 10vw;\r\n    /* font-size: 1.2vw; */\r\n  }\r\n\r\n  .checkbox{\r\n    position:absolute;\r\n    width: 20vw;\r\n    /* margin-top: 1vw;\r\n    font-size: 1.1vw; */\r\n  }\r\n  input[type=checkbox]{\r\n    margin-right: 0.4vw;\r\n    height: 1vw;\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    .google-login-button{\r\n      margin-left: 14vh;\r\n      margin-top: 2vw;\r\n    }\r\n    .log{\r\n      margin-left: 26vw;\r\n    }\r\n    input[type=checkbox]{\r\n      margin-right: 0.4vw;\r\n      height: 10px;\r\n      width: 10px;\r\n    }\r\n    .checkbox{\r\n      width: 35vw;\r\n    }\r\n    .log-left{\r\n      width: 70vw;\r\n    }\r\n    .login-log{\r\n      margin-left: 9vh;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
