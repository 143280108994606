import React, { useRef, useState } from "react"

import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import GoogleLoginButton from "./googlelogin"
import './login.css'

// import emailIcon from '../assets/email.svg';
// import lockIcon from '../assets/padlock 1.svg';
import tv from '../assets/tv.png';

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const rememberMeRef = useRef();
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)

      const rememberMe = rememberMeRef.current.checked;
      if (rememberMe) {
        localStorage.setItem("rememberedUser", emailRef.current.value);
      }
      history("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <>

      <div className="page-container">
        <div className="half-container left log-left">

          <div className="card1">
            <h2 className=" mb-4">Sign in</h2>
            <div className="w-100 text-left mt-2 mb-2">
              If you don't have an account register <br /> You can  <Link to="/signup">Register here!</Link>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span >
                    <img src={emailIcon} alt="Email Icon" className="icone"/>
                  </span> */}
                  </div>
                  <input type="email" className="inputField form-control custom-placeholder-color" ref={emailRef} required placeholder="Enter your email" />
                </div></div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span >
                    <img src={lockIcon} alt="Email Icon" className="icone"/>
                  </span> */}
                  </div>
                  <input type="password" className="inputField form-control custom-placeholder-color" ref={passwordRef} required placeholder="Enter your password" />
                </div>
                <div className=" text-left mt-3 checkbox">
                  <label>
                  <input  type="checkbox" className="box" ref={rememberMeRef} />
                    Remember Me
                    
                  </label>
                </div>
                <div className="w-100 text-right z-index-1 mt-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div></div>
              <button disabled={loading} className="reg log login-log" type="submit">
                Login
              </button>
            </form>

            <GoogleLoginButton />
          </div>


        </div>

        {!isSmallScreen && (
          <div className="half-container">
            <div className="right">
              <img src={tv} alt="TV Icon" className="tv" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
